
import { Thumbnail } from "../../types";
import { defineComponent, PropType } from "@vue/runtime-core";
export default defineComponent({
  props: {
    thumbnail: {
      type: Object as PropType<Thumbnail>,
      required: true,
    },
    href: {
      type: String,
      default: "/",
      required: true,
    },
    buttonName: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    smalltitle: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    rtl: {
      type: Boolean,
      default: false,
    },
  },
});
