
import { defineComponent } from "vue";
import AboutUs from "../../components/AboutUs/AboutUs.vue"
import fetchAboutOffer from "../../misc/composition/fetchAboutOffer"
export default defineComponent({
  name: "Home",
  components: {
  AboutUs
  },
  setup() {
    //
    const {offer, loading} = fetchAboutOffer();
    return {offer,loading};
  },
});
