
import { defineComponent } from "vue";
import TestimonialSlider from "../components/TestimonialSlider/TestimonialSlider.vue";
import fetchTestimonialSlider from "../misc/composition/fetchTestimonialSlider";
import SectionWithImage from "../components/SectionWithImage/SectionWithImage.vue";
import fetchSectionWithImage from "../misc/composition/fetchHomSectionWithImage";
import Jumbotron from "../components/Jumbotron/Jumbotron.vue";
import SectionHomeInfo from "../components/SectionHomeInfo/SectionHomeInfo.vue";
import SectionAboutUs from "../components/SectionAboutUs/SectionAboutUs.vue";
import SectionAboutUsSecond from "../components/SectionAboutUsSecond/SectionAboutUsSecond.vue";
import Hero from "@/components/Hero/Hero.vue";
import FAQ from "../components/FAQ/FAQ.vue";
import fetchHomeFAQ from "../misc/composition/fetchHomeFAQ";
import LoadingPageAnim from "../components/LoadingPageAnim.vue"
export default defineComponent({
  components: {
    Hero,
    TestimonialSlider,
    SectionWithImage,
    Jumbotron,
    FAQ,
    SectionHomeInfo,
    SectionAboutUs,
    SectionAboutUsSecond,
    LoadingPageAnim
  },
  setup() {
    const {
      testimonial,
      loading: loadingTestimonial,
    } = fetchTestimonialSlider();
    const { section, loading: sectionLoading } = fetchSectionWithImage();
    document.title = "About - Coinflip";
    const { faq, loading: loadingFaq } = fetchHomeFAQ();

    return {
      testimonial,
      loadingTestimonial,
      section,
      sectionLoading,
      faq,
      loadingFaq,
    };
  },
});
