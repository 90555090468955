import { getSingle } from "../utilis/api/wrappers";
import { camelizeKeys } from "../utilis/api/helpers";
import { GET } from "../utilis/api/endpoints";
import {ref} from "vue";
import { Image } from "../../types";
import { Link } from "../../types"; 

type ABOUTOFFER = {
  slug: string;
  title:string,
  smalltitle:string,
  description:string,
  subtitle:string,
  href:Link,
  image:Image,
}

export default function fetchAboutOffer() {
  const offer = ref<ABOUTOFFER>({} as ABOUTOFFER);
  const loading = ref<boolean>(true);
  const fetchOffer= () => {
    getSingle<ABOUTOFFER>(GET.ABOUT_OFFER).then((result) => {
      loading.value = false;
      offer.value = camelizeKeys(result);
    });
  };
  fetchOffer();
  return{offer,loading,fetchOffer}
}
