import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-b31acc6c")
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "about-offer" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = {
  key: 0,
  class: "container"
}
const _hoisted_5 = {
  key: 0,
  class: "fluid-container hide-on-mobile"
}
const _hoisted_6 = {
  key: 1,
  class: "fluid-container hide-on-mobile"
}
const _hoisted_7 = { class: "about-offer-second" }
const _hoisted_8 = { class: "container" }
const _hoisted_9 = { class: "container" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_page_anim = _resolveComponent("loading-page-anim")!
  const _component_hero = _resolveComponent("hero")!
  const _component_SectionAboutUs = _resolveComponent("SectionAboutUs")!
  const _component_testimonial_slider = _resolveComponent("testimonial-slider")!
  const _component_Jumbotron = _resolveComponent("Jumbotron")!
  const _component_SectionWithImage = _resolveComponent("SectionWithImage")!
  const _component_FAQ = _resolveComponent("FAQ")!
  const _component_SectionAboutUsSecond = _resolveComponent("SectionAboutUsSecond")!
  const _component_SectionHomeInfo = _resolveComponent("SectionHomeInfo")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_layout = _resolveComponent("a-layout")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading_page_anim, { loading: _ctx.loadingTestimonial }, null, 8, ["loading"]),
    _createVNode(_component_a_layout, null, {
      default: _withCtx(() => [
        _createVNode(_component_a_layout_content, null, {
          default: _withCtx(() => [
            (!_ctx.loadingTestimonial)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createVNode(_component_hero, {
                    title: "About Us",
                    backgroundImage: 
            _ctx.$getFileSource('images/background/bg_certified_casions.png')
          ,
                    breadcrumbs: [
            { href: '/', name: 'Coinflip' },
            { href: '#', name: 'About Us' },
          ]
                  }, null, 8, ["backgroundImage"]),
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                      _createVNode(_component_SectionAboutUs)
                    ])
                  ]),
                  _createElementVNode("div", {
                    class: "container-testimonials",
                    style: _normalizeStyle({
            'background-image':
              'url(' +
              require('../assets/images/background/bg_testimonials.jpeg') +
              ')',
          })
                  }, [
                    (!_ctx.loadingTestimonial)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                          _createVNode(_component_testimonial_slider, {
                            items: _ctx.testimonial.testimonials
                          }, null, 8, ["items"])
                        ]))
                      : _createCommentVNode("", true)
                  ], 4),
                  (!_ctx.sectionLoading)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _createVNode(_component_SectionWithImage, {
                          thumbnail: _ctx.section.thumbnail
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_Jumbotron, {
                              title: _ctx.section.title,
                              subtitle: _ctx.section.subtitle,
                              description: _ctx.section.description,
                              buttonName: _ctx.section.buttonName,
                              href: 'contact'
                            }, null, 8, ["title", "subtitle", "description", "buttonName"])
                          ]),
                          _: 1
                        }, 8, ["thumbnail"])
                      ]))
                    : _createCommentVNode("", true),
                  (!_ctx.loadingFaq)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        _createVNode(_component_SectionWithImage, {
                          thumbnail: _ctx.faq.thumbnail,
                          rtl: true
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_FAQ, {
                              items: _ctx.faq.faqList
                            }, null, 8, ["items"])
                          ]),
                          _: 1
                        }, 8, ["thumbnail"])
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(_component_SectionAboutUsSecond)
                    ])
                  ]),
                  _createElementVNode("div", {
                    class: "container-info",
                    style: _normalizeStyle({
            'background-image':
              'url(' +
              require('../assets/images/background/contact_cover.jpg') +
              ')',
          })
                  }, [
                    _createElementVNode("div", _hoisted_9, [
                      _createVNode(_component_SectionHomeInfo, { class: "info-cards" })
                    ])
                  ], 4)
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}