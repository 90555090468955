import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AboutUs = _resolveComponent("AboutUs")!

  return (!_ctx.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_AboutUs, {
          rtl: true,
          title: _ctx.offer.title,
          thumbnail: _ctx.offer.thumbnail,
          smalltitle: _ctx.offer.smalltitle,
          description: _ctx.offer.description,
          subtitle: _ctx.offer.subtitle,
          href: _ctx.offer.button.href,
          buttonName: _ctx.offer.button.name
        }, null, 8, ["title", "thumbnail", "smalltitle", "description", "subtitle", "href", "buttonName"])
      ]))
    : _createCommentVNode("", true)
}